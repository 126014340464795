import Vue from 'vue'
import App from './App.vue'
import axios from 'axios';
import store from './store.js';
import router from './router/router';
import iosAlertView from 'vue-ios-alertview';

Vue.config.productionTip = false

window.url = "https://adm.pcbchina.ru/backEnd/index.php";
window.sendRequest = function (url, param) {
  let fd = new FormData();
  for (const item in param) {
    fd.append(item, param[item]);
  }

  return new Promise(function (resolve, reject) {
    axios.post(window.url + url,
      fd, {
      headers: {
        auth: `${localStorage.getItem('key') ? localStorage.getItem('key') : null}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
    ).then((response) => {
      if (response) {
        if (response.data && response.data.auth === false) {
          if (router.history.current.fullPath !== "/auth")
            router.push('/auth');
          return;
        }
        resolve(response.data ? response.data : null);
      }
    })
      .catch(function (error) {
        console.error(error);
        reject({ status: 1, errorText: error });
      });
  });

}

Vue.use(iosAlertView);

new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app')
