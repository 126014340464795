<template>
  <div class="admin__user__wrapper">
    <div class="user__header">
      <h4>Список пользователей</h4>
    </div>
    <div class="all-mas">
      <input
        type="text"
        autocomplete="off"
        name="purchaseNumber"
        property="1"
        v-model="notice"
        @change="func_save_notice"
      />
      <a style="color: #333"> Уведомление для всех</a>
    </div>
    <br />

    <div class="user__body">
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Имя</th>
            <th>E-mail</th>
            <th>Заказов</th>

            <th>Зарегистрирован</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in users" :key="item.user_id">
            <td>{{ item.user_id }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.email }}</td>
            <td>{{ item.count_order }}</td>
            <td>{{ new Date(item.create_date).toLocaleDateString() }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      users: null,
      notice: "",
    };
  },
  methods: {
    func_get_users() {
      window.sendRequest("/get_users", {}).then((result) => {
        if (!result) return;
        this.users = result;
      });
    },
    func_save_notice() {
      window.sendRequest("/save_notice", {
        notice: this.notice,
      });
    },
    func_get_notice() {
      window
        .sendRequest("/get_notice", {})
        .then((result) => (this.notice = result));
    },
  },
  mounted() {
    this.func_get_users();
    this.func_get_notice();
  },
};
</script>


<style></style>
