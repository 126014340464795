<template>
  <div class="wrapper__info">
    <div class="info__form">
      <div class="header__form">
        <button @click="func_close">X</button>
      </div>
      <div class="form">
        <div class="order-detals" v-if="order">
          <div class="detals-tab">
            <div class="detals-tab-bod">
              <div class="detals-tab-row">
                <div class="detals-tab-cell">
                  <div class="detals-order">{{ order.order_id }}</div>
                  <div class="detals-time">
                    от
                    {{
                      new Date(order.create_datetime).toLocaleDateString() +
                      " " +
                      new Date(order.create_datetime).toLocaleTimeString()
                    }}
                  </div>
<div  v-if="order.email == 'alexey.taber@gmail.com'" style="color: rgb(192, 58, 58);font-size: 11px;">
Новый заказ не принимать!
</div>
<div  v-if="order.phone == '9163651765'" style="color: rgb(192, 58, 58);font-size: 11px;">
Новый заказ не принимать!
</div>
<div  v-if="order.company == 'Табер Алексей Анатольевич'" style="color: rgb(192, 58, 58);font-size: 11px;">
Новый заказ не принимать!
</div>


<div  v-if="order.company == 'Гребнев Матвей Михайлович'" style="color: rgb(192, 58, 58);font-size: 11px;">
торчит за доставку 350р по заказу 1417
</div>

<div  v-if="order.phone == '79233610103'" style="color: rgb(192, 58, 58);font-size: 11px;">
торчит за доставку 350р по заказу 1417
</div>

<div  v-if="order.phone == '9233610103'" style="color: rgb(192, 58, 58);font-size: 11px;">
торчит за доставку 350р по заказу 1417
</div>

<div  v-if="order.email == 'b21cergei@gmail.com'" style="color: rgb(192, 58, 58);font-size: 11px;">
торчит за доставку 350р по заказу 1417
</div>

                  <div>
                    <select
                      style="border: 1px solid rgb(200, 194, 45)"
                      v-model="order.status_id"
                    >
                      <option
                        v-for="item in status"
                        :key="item.status_id"
                        :value="item.status_id"
                      >
                        {{ item.status }}
                      </option>
                    </select>

                    <div class="btnpay"></div>
                    <div class="zamet">
                      Карта для оплаты

                      <select v-model="order.card">
                        <option value="1">Карта 1</option>
                        <option value="2">Карта 2</option>
                        <option value="3">Карта 3</option>
                      </select>
                    </div>
                    <div class="zamet">
                      Сумма оплаты
                      <input class="filt" type="number" v-model="order.price" />
                      <select v-model="order.bank">
                        <option></option>

                        <option value="На карту">На карту</option>
                        <option value="Онлайн">Онлайн</option>
                        <option value="Юр.Лицо">Юр.Лицо</option>
                      </select>
                    </div>

                    <div class="zamet">
                      Администратор:
                      <input type="text" v-model="order.admin" />
                    </div>
                    <div class="zamet">
                      Для себя:
                      <input type="text" v-model="order.info" />
                    </div>
                    <div class="zamet">
                      Номер отправления:
                      <input
                        type="text"
                        v-model="order.num_departure"
                        @change="func_save_num_departure"
                      />
                    </div>
                    <div class="zamet">
                      Технический вопрос
                      <input
                        type="checkbox"
                        id="coding"
                        name="interest"
                        v-model="order.tex"
                      />
                    </div>

                    <div class="btnpay"></div>
                    <div class="zamet">
                      Замена файла
                      <select v-model="order.zamena">
                        <option value="Не требуется">Не требуется</option>
                        <option value="Требуется">Требуется</option>
                        <option value="Заменен">Заменен</option>
                      </select>
                    </div>

                    <div class="zamet">
                      Текст доплаты:
                      <input type="text" v-model="order.doplata" />
                    </div>
                    <br />
                    <div class="imgdown">
                      <button @click="func_edit_image">Загрузить</button> <br />
                      <img
                        :src="
                          order.img
                            ? 'https://adm.pcbchina.ru/img/upload/' + order.img
                            : require('@/assets/11.jpg')
                        "
                        alt=""
                        class="imgmain-detals"
                      />
                    </div>
                    <div class="client-adress">

                    
<div  v-if="order.tipdost == 'СДЕК'" style="color: rgb(63 208 69);font-size: 31px;margin-bottom: 22px;">
{{ order.tipdost }}
</div>

<div  v-if="order.tipdost == 'Почта России'" style="color: rgb(240 243 255);font-size: 24px;margin-bottom: 20px;background: #4e97fc;border-radius: 6px;padding: 5px;text-align: center;">
{{ order.tipdost }}
</div>



                      Город: {{ order.city }} <br />
                      Получатель: {{ order.company }} <br />
                      Телефон: {{ order.phone }} <br />
                      email: {{ order.email }}<br />

                      login: {{ order.login }}<br />
                      api: {{ order.api_key }}<br />

                    
                    </div>

                    <div class="print">
                      <button @click="funC_print">Печать</button>
                    </div>
                  </div>
                </div>
                <div
                  class="detals-tab-cell one-maket"
                  v-if="order.mak == 'Один макет PCB'"
                >
                  <div class="detals-nast">
                    Файл:
                    <a :href="'https://app.pcbchina.ru/files/' + order.file">{{
                      order.file
                    }}</a
                    ><br />
                    <div class="zamet">
                      Размер:
                      <input type="text" class="razm" v-model="order.razx" /> x
                      <input type="text" class="razm" v-model="order.razy" />
                    </div>
                    <div class="zamet">
                      Материал:

                      <select class="select_sd" v-model="order.material">
                        <option value="FR-4">FR-4</option>
                        <option value="Алюминий">Алюминий</option>
                        <option value="Медь">Медь</option>
                        <option value="Шлейф">Шлейф</option>
                        <option value="Роджерс">Роджерс</option>
                        <option value="ПТФЕ">ПТФЕ</option>
                      </select>
                    </div>

                    <div class="zamet">
                      Слои:

                      <select class="select_sd" v-model="order.sloi">
                        <option value="1-2">1-2</option>
                        <option value="4">4</option>
                        <option value="6">6</option>
                        <option value="8">8</option>
                        <option value="10">10</option>
                        <option value="12">12</option>
                        <option value="14">14</option>
                      </select>
                    </div>

                    <div class="zamet">
                      Количество:
                      <input
                        type="text"
                        class="razm select_sd"
                        v-model="order.count"
                      />
                    </div>

                    <div class="zamet">
                      толщина платы:

                      <select class="select_sd" v-model="order.bold">
                        <option value="0.4">0.4</option>
                        <option value="0.6">0.6</option>
                        <option value="0.8">0.8</option>
                        <option value="1.0">1.0</option>
                        <option value="1.2">1.2</option>
                        <option value="1.6">1.6</option>
                        <option value="2.0">2.0</option>
                      </select>
                    </div>
                    <div class="zamet">
                      Толщина дорожки:

                      <select class="select_sd" v-model="order.level">
                        <option value="0.08 мм">0.08</option>
                        <option value="0.11 мм">0.11</option>
                        <option value="0.13 мм">0.13</option>
                        <option value="0.16 мм">0.16</option>
                      </select>
                    </div>
                    <div class="zamet">
                      Мин отверстие:

                      <select class="select_sd" v-model="order.min_shere">
                        <option value="0.15 мм">0.15</option>
                        <option value="0.20 мм">0.20</option>
                        <option value="0.25 мм">0.25</option>
                        <option value="0.30 мм">0.30</option>
                      </select>
                    </div>

                    <div class="zamet">
                      Медь:

                      <select class="select_sd" v-model="order.med">
                        <option value="1 oz">1 oz</option>
                        <option value="2 oz">2 oz</option>
                      </select>
                    </div>

                    <div class="zamet">
                      Цвет:

                      <select class="select_sd" v-model="order.color">
                        <option value="Зеленый">Зеленый</option>
                        <option value="Фиолетовый">Фиолетовый</option>
                        <option value="Красный">Красный</option>
                        <option value="Желтый">Желтый</option>
                        <option value="Синий">Синий</option>
                        <option value="Белый">Белый</option>
                        <option value="Черный">Черный</option>
                      </select>
                    </div>

                    <div class="zamet">
                      Финишное покрытие:

                      <select class="select_sd" v-model="order.finish">
                        <option value="Оловянно-свинцовое">
                          Оловянно-свинцовое
                        </option>
                        <option value="Олово">Олово</option>
                        <option value="Золото">Золото</option>
                      </select>
                    </div>

                    <div class="zamet">
                      Трафарет:

                      <select class="select_sd" v-model="order.trafaret">
                        <option value="Нет">Нет</option>
                        <option value="Верх">Верх</option>
                        <option value="Низ">Низ</option>
                        <option value="Верх + Низ">Верх + Низ</option>
                      </select>
                    </div>

                    <div class="zamet">
                      Размер:

                      <select class="select_sd" v-model="order.trafaretsize">
                        <option value="50х50">50х50</option>
                        <option value="100х100">100х100</option>
                        <option value="150х150">150х150</option>
                        <option value="200х190">200х190</option>
                        <option value="290х190">290х190</option>
                        <option value="380х280">380х280</option>
                        <option value="420х320">420х320</option>
                        <option value="450х350">450х350</option>
                        
                      </select>
                    </div>

                    <div class="zamet">
                      {{ order.trafaretfreim }} {{ order.trafaretpol }}
                      
                    </div>


                    <div class="zamet">
                      Удалить номер заказа:

                      <select class="select_sd" v-model="order.noorder">
                        <option value="Нет">Нет</option>
                        <option value="Да">Да</option>
                        <option value="Разместить в указанном месте">
                          Разместить в указанном месте
                        </option>
                      </select>
                    </div>

                    <div class="zamet">
                      Панелирование:
                      <input type="text" class="razm" v-model="order.panelx" />
                      x
                      <input type="text" class="razm" v-model="order.panely" />
                    </div>

                    <div class="zamet">
                      Другой дизайн:

                      <select class="select_sd" v-model="order.diz">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                      </select>
                    </div>

                    Дополнительные настройки:{{ order.setting_info }}
                    <br />
                  </div>
                </div>





                <div
                  class="detals-tab-cell opt-maket"
                  v-if="order.mak == 'Оптовый заказ PCB'"
                >
                  <div class="detals-nast">
                    Файл:
                    <a :href="'https://app.pcbchina.ru/files/' + order.file">{{
                      order.file
                    }}</a
                    ><br />

                    Дополнительные настройки:{{ order.setting_info }}
                    <br />
                  </div>
                </div>

                <div
                  class="detals-tab-cell drzakaz"
                  v-if="order.mak == 'Другой заказ'"
                >
                  <div class="detals-nast">
                    <div class="zamet">
                      Сервис:
                      <input
                        type="text"
                        class="razm select_sd"
                        v-model="order.service"
                      />
                    </div>

                    <div class="zamet">
                      Логин:
                      <input
                        type="text"
                        class="razm select_sd"
                        v-model="order.loginps"
                      />
                    </div>

                    <div class="zamet">
                      Пароль:
                      <input
                        type="text"
                        class="razm select_sd"
                        v-model="order.password"
                      />
                    </div>

                    <div class="zamet">
                      Трек SF:
                      <input
                        type="text"
                        class="razm select_sd"
                        v-model="order.tracksf"
                      />
                    </div>

                    Дополнительные настройки:{{ order.setting_info }}
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <input
      type="file"
      id="file"
      ref="file"
      v-on:change="func_file_upload()"
      style="display: none"
      accept="image/*"
    />

    <div class="print" style="display: none" id="print">
      <div v-html="prtHtml1"></div>
      <barcode
        :value="order.num_departure"
        width="4"
        height="100"
        :options="{
          lineColor: '#007bff',
          fontSize: '150',
          lineColor: '#990000',
          marginLeft: 150,
          marginTop: 1,
          background: '#dddddd',
        }"
      >
        Show this if the rendering fails.
      </barcode>
    </div>
  </div>
</template>

<script>
import VueBarcode from "vue-barcode";

export default {
  components: {
    barcode: VueBarcode,
  },
  props: {
    order: Object,
  },
  data() {
    return {
      status: null,
      file: "",
      prtHtml1: "",
      barcode: "",
      order_old: null,
    };
  },
  methods: {
    func_get_status() {
      window.sendRequest("/get_status", {}).then((result) => {
        if (!result) return;
        this.status = result;
      });
    },
    func_close() {
      this.$iosConfirmNew({
        title: "Сохранить?",
        buttons: [
          {
            text: "Вернуться",
            bold: true,
          },
          {
            text: "Выйти",
            bold: true,
          },
          {
            text: "Сохранить",
            bold: true,
          },
        ],
      }).then((res) => {
        if (res.index == 2) {
          window.sendRequest("/save_order", this.order).then(() => {
            this.$emit("close_modal");
          });
        } else if (res.index == 1) {
          for (const key in this.order) {
            let element = this.order[key];
            for (const key2 in this.order_old) {
              let element2 = this.order_old[key2];
              if (key == key2 && element != element2) {
                this.order[key] = this.order_old[key2];
              }
            }
          }
          this.$emit("close_modal");
        }
      });
    },
    func_get_status_one(order) {
      return this.status.find((x) => x.status_id == order.status_id) || {};
    },

    func_save_num_departure() {
      window.sendRequest("/update_num_departure_order", {
        order_id: this.order.order_id,
        num_departure: this.order.num_departure,
      });
    },

    funC_print() {
      const prtHtml = document.getElementById("print").innerHTML;
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          ${stylesHtml}
        </head>
        <body>
          ${prtHtml}
        </body>
      </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
    },
    func_file_upload() {
      this.file = this.$refs.file.files[0];
      window
        .sendRequest("/update_image", {
          order_id: this.order.order_id,
          file: this.file,
        })
        .then((result) => {
          if (!result) return;
          if (result.img) {
            this.order.img = result.img;
          }
        });
    },
    func_edit_image() {
      document.querySelector("#file").click();
    },
    func_get_print() {
      //таблица mails where mail_id = 6
      window.sendRequest("/get_print", {}).then((result) => {
        if (!result) return;
        this.prtHtml1 = result;
        this.prtHtml1 = this.prtHtml1.replace(
          "*order_id*",
          this.order.order_id
        );
        this.prtHtml1 = this.prtHtml1.replace(
          "*create_datetime*",
          this.order.create_datetime
        );
        this.prtHtml1 = this.prtHtml1.replace("*file*", this.order.file);
        this.prtHtml1 = this.prtHtml1.replace("*count*", this.order.count);
        this.prtHtml1 = this.prtHtml1.replace("*admin*", this.order.admin);
        this.prtHtml1 = this.prtHtml1.replace("*city*", this.order.city);
        this.prtHtml1 = this.prtHtml1.replace("*phone*", this.order.phone);
        this.prtHtml1 = this.prtHtml1.replace("*company*", this.order.company);
        this.prtHtml1 = this.prtHtml1.replace("*tipdost*", this.order.tipdost);
        this.prtHtml1 = this.prtHtml1.replace("*email*", this.order.email);
        this.prtHtml1 = this.prtHtml1.replace("*name*", this.order.name);
      });
    },





  },

 
  mounted() {
    if (this.order) this.order_old = { ...this.order };
    this.func_get_status();
    this.func_get_print();
  },
};
</script>

<style>

</style>
