<template>
  <div class="admin__wrapper">
    <div class="setting__link">
      <div
        class="item__link"
        v-for="item in links.filter((x) => x.hidden === false)"
        :key="item.id"
        :class="{ active: item.active }"
        @click="func_select_link(item)"
      >
        <div class="content">
          <div class="img">
            <div class="svg" v-if="item.id === 5">
              <img :src="require('@/assets/s.svg')" />
            </div>
            <i v-else class="fa" :class="[item.css]" aria-hidden="true"></i>
          </div>
          <div class="text">{{ item.text }}</div>
        </div>
      </div>
    </div>
    <div class="setting__body">
      <Orders v-if="selected_id === 1" />
      <Users
        v-if="selected_id === 2"
        @show_user="func_show_user"
        :update_info="user_info"
      />
      <Finance v-if="selected_id === 3" />

      <User
        v-if="show_user"
        :id="show_user"
        @close_user="func_show_user(null)"
        @update_balance="func_update_balance_users"
        @remove_user="func_remove_user"
      />
      <Promo v-if="selected_id === 5" />
    </div>
  </div>
</template>

<script>
import Users from "@/components/admin/users.vue";
import Finance from "@/components/admin/finance.vue";
import Orders from "@/components/admin/orders.vue";
import User from "@/components/admin/user.vue";
import Promo from "@/components/admin/promo.vue";
export default {
  components: {
    Users,
    Finance,
    Orders,
    User,
    Promo,
  },
  data() {
    return {
      selected_id: 1,
      show_user: null,
      user_info: null,
      links: [
        {
          id: 1,
          text: "Заказы",
          css: "fa-newspaper-o",
          active: true,
          hidden: false,
        },
        {
          id: 2,
          text: "Пользователи",
          css: "fa-users",
          active: false,
          hidden: false,
        },
        {
          id: 3,
          text: "Финансы",
          css: "fa-credit-card-alt",
          active: false,
          hidden: false,
        },
      ],
    };
  },
  methods: {
    func_show_user(user_id) {
      this.show_user = user_id;
    },
    func_select_link(item) {
      this.links.forEach((obj) => {
        obj.active = false;
      });
      item.active = true;
      this.selected_id = item.id;
    },
    func_update_balance_users(obj) {
      this.user_info = obj;
    },
    func_remove_user() {
      this.selected_id = 2;
      setTimeout(() => {
        this.selected_id = 1;
      }, 1000);
    },
  },
  mounted() {
    if (!localStorage.getItem("key")) {
      this.$router.push("/auth");
    }
  },
};
</script>

<style>
.svg img {
  width: 60%;
}
</style>
