<template>
  <div class="admin__finance__wrapper">
    <div class="finance__header"></div>
    <div class="finance__body">
      <div class="finance__setting"></div>
      <div class="card" v-for="item in cards" :key="item.card_id">
        <a style="color: #333"> Карта {{ item.card_id }}</a>
        <br />
        <input
          type="text"
          autocomplete="off"
          name="purchaseNumber"
          property="1"
          v-model="item.num"
        />
        <a style="color: #333"> Номер карты</a> <br />

        <input
          type="text"
          autocomplete="off"
          name="purchaseNumber"
          property="1"
          v-model="item.name"
        />
        <a style="color: #333"> Получатель</a><br />
      </div>
      <button @click="func_save_card">Сохранить</button>
      <div class="finance__table">
        <div class="filter__content"></div>

        <table class="finance__table_t">
          <thead>
            <tr>
              <th>Дата</th>
              <th>Заказ</th>
              <th>ID</th>
              <th>Тип оплаты</th>
              <th>Сумма</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in finance" :key="item.finance_id">
              <td>{{ new Date(item.create_datetime).toLocaleDateString() }}</td>
              <td>{{ item.order_id }}</td>
              <td>{{ item.finance_id }}</td>
              <td>{{ item.bank }}</td>
              <td>{{ Number(item.price || 0).toFixed(2) }}</td>
            </tr>
          </tbody>
        </table>
        <!-- <button @click="func_add_more">Показать еще</button> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      finance: null,
      cards: null,
    };
  },
  methods: {
    func_get_finance() {
      window.sendRequest("/get_finance", {}).then((result) => {
        if (!result) return;
        this.finance = result;
      });
    },
    func_get_cards() {
      window.sendRequest("/get_cards", {}).then((result) => {
        if (!result) return;
        this.cards = result;
      });
    },
    func_save_card() {
      window
        .sendRequest("/save_cards", { cards: JSON.stringify(this.cards) })
        .then(() => {
          alert("Сохранено");
        });
    },
  },
  mounted() {
    this.func_get_finance();
    this.func_get_cards();
  },
};
</script>

<style></style>
