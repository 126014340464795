import Vue from 'vue'
import Router from 'vue-router'

import Auth from '@/components/pages/auth.vue'
import Main from '@/components/pages/main.vue'



Vue.use(Router);

let router = new Router({
  mode: 'history',
  routes: [{
    path: '/',
    component: Main
  }, {
    path: '/auth',
    name: 'auth',
    component: Auth
  }]
})

export default router;