import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    user_setting: null,
    message_list: [],
    message_no_show: false,
    load_user: false,
    fields: null
  },
  mutations: {
    CHANGE_USER: (state, user) => {
      state.user_setting = user;
    },
    CHANGE_MESSAGE_LIST: (state, message_list) => {
      state.message_list = message_list;
    },
    CHANGE_MESSAGE_NO_SHOW: (state, message_no_show) => {
      state.message_no_show = message_no_show;
    },
    CHANGE_COUNT_DAY: (state, count_day) => {
      state.count_day = count_day;
    },
    CHANGE_LOAD_USER: (state, load_user) => {
      state.load_user = load_user;
    },
    CHANGE_FIELDS: (state, fields) => {
      state.fields = fields;
    }
  },
  actions: {
    TOGGLE_USER({ commit }, user) {
      commit('CHANGE_USER', user)
    },
    TOGGLE_MESSAGE_LIST({ commit }, message_list) {
      commit('CHANGE_MESSAGE_LIST', message_list)
    },
    TOGGLE_MESSAGE_NO_SHOW({ commit }, message_no_show) {
      commit('CHANGE_MESSAGE_NO_SHOW', message_no_show)
    },
    TOGGLE_COUNT_DAY({ commit }, count_day) {
      commit('CHANGE_COUNT_DAY', count_day)
    },
  },
  getters: {
    USER_STATE(state) {
      return state.user_setting;
    },
    MESSAGE_LIST_STATE(state) {
      return state.message_list;
    },
    MESSAGE_NO_SHOW_STATE(state) {
      return state.message_no_show;
    },
    COUNT_DAY_STATE(state) {
      return state.count_day;
    },
    LOAD_USER_STATE(state) {
      return state.load_user;
    },
    FIELDS_STATE(state) {
      return state.fields;
    },
  }
});

export default store;