<template>
  <div>
    <modalInfoVue
      v-if="selected_order"
      :order="selected_order"
      @close_modal="func_close_order"
    />
    <div class="filter">
      <div class="filter-al">
        <div class="filter__item" v-for="item in filters" :key="item.status_id">
          <input
            type="checkbox"
            id="coding"
            name="interest"
            v-model="item.selected"
          />
          {{ item.status }}
          <br />
        </div>

        <br />
        <br />
        <input
          type="checkbox"
          id="coding"
          name="interest"
          v-model="filter_down.tex"
        />
        Технический вопрос
        <br />
        <input
          type="checkbox"
          id="coding"
          name="interest"
          checked="checked"
          v-model="filter_down.disable"
        />
        Удален
        <br />
        <br /><br />
        <input type="text" class="filt" v-model="search.order_id" />
        Поиск по номеру заказа
        <br />

        <input type="text" class="filt" v-model="search.email" />
        Поиск по e-mail
        <br />
        <input type="text" class="filt" v-model="search.phone" />
        Поиск по телефону
        <br />
      </div>
    </div>
    <div class="order__card" v-for="item in filter_orders" :key="item.order_id">
      <div class="btn-ord-img tooltip">
        <img
          :src="
            item.status_id == 5
              ? require('@/assets/fot.jpg')
              : item.img
              ? 'https://adm.pcbchina.ru/img/upload/' + item.img
              : require('@/assets/11.jpg')
          "
          alt=""
          class="imgmain"
        />
      </div>
      <div
        class="btn-ord tooltip"
        type="button"
        @click="func_select_order(item)"
      >
        i
      </div>
      <div class="btn-ord-date tooltip">
        {{
          new Date(item.create_datetime).toLocaleDateString() +
          " " +
          new Date(item.create_datetime).toLocaleTimeString()
        }}
      </div>

      <div class="btn-ord-order tooltip">{{ item.order_id }}</div>
      <div
        class="btn-ord-status tooltip"
        :style="{ background: func_get_status_one(item).color }"
      >
        {{ func_get_status_one(item).status }}
      </div>

      <div class="btn-ord-status-go tooltip" @click="func_next_status(item)">
        <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>
      </div>

      <div class="btn-ord-price tooltip">
        {{ Number(item.price).toFixed(2) }} руб
      </div>
      <div
        class="btn-ord-jlc btn-ord-jlc-t tooltip"
        @click="func_link(item, 1)"
      >
        JLCPCB
      </div>
      <div
        class="btn-ord-next btn-ord-jlc-t tooltip"
        @click="func_link(item, 2)"
      >
        EMAIL
      </div>
      <div class="btn-ord-price-online tooltip" v-if="item.bank == 'Онлайн'">
        Онлайн
      </div>

      <div class="btn-ord-count tooltip">{{ item.count || 0 }} шт</div>
      <div class="btn-ord-file tooltip">
        <a :href="'https://app.pcbchina.ru/files/' + item.file">{{
          item.file
        }}</a>
      </div>

      <div class="btn-ord-teh" v-if="+item.disable || +item.tex">
        {{ +item.disable ? "Удален" : "Технический вопрос" }}
      </div>

      <div class="btn-ord-upl" v-if="item.zamena == 'Заменен'">
        Файл заменен
      </div>

      <div class="btn-ord-opt" v-if="item.mak == 'Оптовый заказ PCB'">Опт</div>
      <div class="btn-ord-drzak" v-if="item.mak == 'Другой заказ'">
        Другой заказ
      </div>

      <div class="btn-ord-numdil tooltip" v-if="item.num_departure">
        Трек: {{ item.num_departure }}
      </div>
      <div class="btn-ord-adm tooltip" v-if="item.admin">
        Администратор: {{ item.admin }}
      </div>

      <div
        class="btn-ord-mat one-maket tooltip"
        v-if="item.mak == 'Один макет PCB'"
      >
        Размер: <span>{{ item.razx }} x {{ item.razy }}</span> <br />
        Материал:
        <span :style="{ color: item.material != 'FR-4' ? 'red' : '#5e5e5e' }"
          >{{ item.material }} </span
        ><br />
        Слои:
        <span :style="{ color: item.sloi != '1-2' ? 'red' : '#5e5e5e' }"
          >{{ item.sloi }} </span
        ><br />
        Толщина платы:
        <span :style="{ color: item.bold != '1.6' ? 'red' : '#5e5e5e' }"
          >{{ item.bold }} </span
        ><br />
        Толщина дорожки:
        <span :style="{ color: item.level != '0.16 мм' ? 'red' : '#5e5e5e' }"
          >{{ item.level }} </span
        ><br />
        Толщина отверстия:
        <span
          :style="{ color: item.min_shere != '0.30 мм' ? 'red' : '#5e5e5e' }"
          >{{ item.min_shere }} </span
        ><br />
        Медь:
        <span :style="{ color: item.med != '1 oz' ? 'red' : '#5e5e5e' }"
          >{{ item.med }} </span
        ><br />
        Цвет:
        <span :style="{ color: item.color != 'Зеленый' ? 'red' : '#5e5e5e' }"
          >{{ item.color }} </span
        ><br />
        Финишное покрытие:
        <span
          :style="{
            color: item.finish != 'Оловянно-свинцовое' ? 'red' : '#5e5e5e',
          }"
          >{{ item.finish }}</span
        ><br />
        Удалить номер заказа:
        <span :style="{ color: item.noorder != 'Нет' ? 'red' : '#5e5e5e' }">{{
          item.noorder
        }}</span
        ><br />
        Другой дизайн:
        <span :style="{ color: item.diz != '1' ? 'red' : '#5e5e5e' }"
          >{{ item.diz }} </span
        ><br />
        Панелирование:
        <span
          :style="{
            color: item.panelx != '1' || item.panely != '1' ? 'red' : '#5e5e5e',
          }"
          >{{ item.panelx + "x" + item.panely }} </span
        ><br />
        Трафарет:
        <span :style="{ color: item.trafaret != 'Нет' ? 'red' : '#5e5e5e' }"
          >{{ item.trafaret }}   {{ item.trafaretsize }}  {{ item.trafaretfreim }} {{ item.trafaretpol }}</span
        ><br />
      </div>

      <div
        class="btn-ord-mat opt-maket tooltip"
        v-if="item.mak == 'Оптовый заказ PCB'"
      >
        Опт
      </div>

      <div
        class="btn-ord-mat drzakaz tooltip"
        v-if="item.mak == 'Другой заказ'"
      >
        Сервис: {{ item.service }}<br />
        Логин: {{ item.loginps }}<br />
        Пароль: {{ item.password }}<br /><br />
         <p style=" margin: 0; color: #d050c6; font-size: 20px; ">{{ item.tracksf }} </p> <br />
      </div>
    </div>
  </div>
</template>

<script>
import modalInfoVue from "../modals/modalInfo.vue";
export default {
  components: {
    modalInfoVue,
  },
  data() {
    return {
      orders: null,
      selected_order: null,
      status: null,
      filter_closed: false,
      filter_pay: true,
      search: {
        order_id: "",
        phone: "",
        email: "",
      },
      filters: null,
      filter_down: {
        tex: true,
        disable: false,
      },
    };
  },
  methods: {
    func_close_order() {
      this.selected_order = null;
    },
    func_remove_order(obj) {
      window.sendRequest("/remove_order", {
        order_id: obj.order_id,
      });
    },
    func_show_remove_btn(obj) {
      let status = this.status.find((x) => x.status_id == obj.status_id).status;
      if (
        !Number(obj.disable) &&
        (status == "Обработка" ||
          status == "Ждет оплаты" ||
          status == "Ошибка файла")
      ) {
        return true;
      } else {
        return false;
      }
    },
    func_get_orders() {
      window.sendRequest("/get_orders", {}).then((result) => {
        if (!result) return;
        result.forEach((item) => {
          item.tex = item.tex == "true" ? true : false;
        });
        this.orders = result;
      });
    },
    func_get_more(order) {
      if (+order.disable) return "Удален";
      else return "";
    },
    func_get_status() {
      window
        .sendRequest("/get_status", {})
        .then((result) => {
          if (!result) return;
          this.status = result;
          this.filters = result.map((obj) => ({
            ...obj,
            selected: obj.status_id == 4 || obj.status_id == 6 || obj.status_id == 13? true : false,
          }));
          let element = this.filters.find((x) => x.status_id == 13);
          let fromIndex = this.filters.findIndex((x) => x.status_id == 13);
          this.filters.splice(fromIndex, 1);
          this.filters.splice(this.filters.length - 1, 0, element);
        })
        .then(() => {
          this.func_get_orders();
        });
    },
    func_select_order(order) {
      this.selected_order = order;
    },
    func_get_status_one(order) {
      return this.status.find((x) => x.status_id == order.status_id) || {};
    },
    func_update_status() {
      window.sendRequest("/update_status_order", {
        order_id: this.selected_order.order_id,
        status_id: this.selected_order.status_id,
      });
    },
    func_save_price() {
      window.sendRequest("/update_price_order", {
        order_id: this.selected_order.order_id,
        price: this.selected_order.price,
      });
    },
    func_save_bank() {
      window.sendRequest("/update_bank_order", {
        order_id: this.selected_order.order_id,
        bank: this.selected_order.bank,
      });
    },
    func_save_admin() {
      window.sendRequest("/update_admin_order", {
        order_id: this.selected_order.order_id,
        admin: this.selected_order.admin,
      });
    },
    func_save_info() {
      window.sendRequest("/update_info_order", {
        order_id: this.selected_order.order_id,
        info: this.selected_order.info,
      });
    },
    func_save_num_departure() {
      window.sendRequest("/update_num_departure_order", {
        order_id: this.selected_order.order_id,
        num_departure: this.selected_order.num_departure,
      });
    },
    func_save_tex() {
      window.sendRequest("/update_tex_order", {
        order_id: this.selected_order.order_id,
        tex: this.selected_order.tex ? 1 : 0,
      });
    },
    func_next_status(obj) {
      if (!obj) return;
      this.$iosConfirm("Изменить статус?").then(() => {
        window
          .sendRequest("/next_status", {
            order_id: obj.order_id,
          })
          .then((result) => {
            if (result) obj.status_id = result;
          });
      });
    },
    func_link(obj, type) {
      var link = "";
      if (type == 1) {
        link = `https://cart.jlcpcb.com/quote?orderType=1&stencilLayer=${
          obj.sloi == "1-2" ? 2 : obj.sloi
        }&stencilWidth=${obj.razx}&stencilLength=${obj.razy}&stencilCounts=${
          obj.count
        }`;
      } else {
        link = `https://e.mail.ru/search/?q_query=${obj.email}#/`;
      }
      window.open(link, "_blank");
    },
  },
  mounted() {
    this.func_get_status();
  },
  computed: {
    filter_orders() {
      return [...(this.orders || [])]
        .filter((x) => {
          if (this.search.order_id || this.search.email || this.search.phone) {
            if (
              this.search.order_id &&
              x.order_id
                .toString()
                .toLowerCase()
                .indexOf(this.search.order_id.toString().toLowerCase()) != -1
            ) {
              return true;
            } else if (
              this.search.email &&
              x.email.toLowerCase().indexOf(this.search.email.toLowerCase()) !=
                -1
            ) {
              return true;
            } else if (
              this.search.phone &&
              x.phone.toLowerCase().indexOf(this.search.phone.toLowerCase()) !=
                -1
            ) {
              return true;
            } else {
              return false;
            }
          } else {
            if (
              x.status_id ==
              this.filters.find(
                (a) =>
                  a.status_id == x.status_id &&
                  a.selected == true &&
                  x.disable == 0 &&
                  x.tex == 0
              )?.status_id
            ) {
              return true;
            } else if (this.filter_down.disable && x.disable == 1) {
              return true;
            } else if (this.filter_down.tex && x.tex == 1) {
              return true;
            } else {
              return false;
            }
          }
        })
        .sort((a, b) => {
          let texA = a.tex ? 1 : 0;
          let texB = b.tex ? 1 : 0;
          return (texB > texA) - (texA > texB);
        });
    },
  },
};
</script>
<style></style>
