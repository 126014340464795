<template>
  <div class="user__edit__wrapper">
    <div class="close__btn" @click="$emit('close_user')">
      <i class="fa fa-external-link" aria-hidden="true"></i>
    </div>
    <div class="user__edit__body" v-if="user_info">
      <table>
        <tr>
          <td>User ID</td>
          <td>{{ user_info.user_id }}</td>
        </tr>
        <tr>
          <td>Логин</td>
          <td>
            <input
              type="text"
              v-model="user_info.login"
              @change="func_save_info"
            />
          </td>
        </tr>
        <tr>
          <td>E-mail</td>
          <td>
            <input
              type="text"
              v-model="user_info.email"
              @change="func_save_info"
            />
          </td>
        </tr>
        <tr>
          <td>Телефон</td>
          <td>
            <input
              type="text"
              v-model="user_info.phone"
              @change="func_save_info"
            />
          </td>
        </tr>
        <tr>
          <td>Город</td>
          <td>{{ user_info.city }}</td>
        </tr>
        <tr>
          <td>Дата регистрации</td>
          <td>
            {{
              new Date(user_info.create_date).toLocaleDateString() +
              " " +
              new Date(user_info.create_date).toLocaleTimeString()
            }}
          </td>
        </tr>
        <tr>
          <td>Последняя авторизация</td>
          <td>
            {{
              new Date(user_info.auth_date).toLocaleDateString() +
              " " +
              new Date(user_info.auth_date).toLocaleTimeString()
            }}
          </td>
        </tr>
        <tr>
          <td>Заказов</td>
          <td>{{ user_info.count_order }}</td>
        </tr>
        <tr>
          <td>Клиентов</td>
          <td>{{ user_info.count_customer }}</td>
        </tr>
        <tr>
          <td>Мастеров</td>
          <td>{{ user_info.count_master }}</td>
        </tr>
        <tr>
          <td class="balance__table">
            Баланс
            <span v-if="!!Number(user_info.demo)">demo</span>
          </td>
          <td :style="{ color: func_get_color(user_info) }">
            {{ user_info.balance + " руб" }}
          </td>
        </tr>
      </table>

      <div class="user__function">
        <h4>Функции</h4>
        <table>
          <tr>
            <td>Чат</td>
            <td>
              <div class="toggle-button-cover">
                <div class="button-cover">
                  <div class="button b2" id="button-12">
                    <input type="checkbox" class="checkbox" />
                    <div class="knobs">
                      <span></span>
                    </div>
                    <div class="layer"></div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>

      <div class="user__function">
        <h4>Удаление профиля</h4>
        <table>
          <tr>
            <td>Удалить</td>
            <td>
              <button @click="func_remove_user" class="remove">
                <i class="fa fa-times"></i>
              </button>
            </td>
          </tr>
        </table>
      </div>
      <div class="user__balance__add">
        <form @submit.prevent="func_save_info(true)">
          <input type="number" v-model="new_balance" required />
          <button>Пополнить</button>
        </form>
      </div>
      <FinanceList
        :user_id="Number(user_info.user_id)"
        :refresh_finance="refresh_finance"
      />
    </div>
  </div>
</template>

<script>
import FinanceList from "@/components/finance_list";
export default {
  props: ["id"],
  components: {
    FinanceList,
  },
  data() {
    return {
      user_info: null,
      new_balance: 0,
      refresh_finance: false,
    };
  },
  methods: {
    func_get_data() {
      let url = "/get_user_info";
      window.sendRequest(url, { user_info_id: this.id }).then((result) => {
        if (!result) return;
        this.user_info = result;
      });
    },
    func_get_color(item) {
      let balance = Number(item.balance);
      let color = "";
      if (balance <= 0) {
        color = "#c94e4e";
      } else if (balance > 0 && balance < 50) {
        color = "#9f6427";
      } else {
        color = "#217e2d";
      }
      return color;
    },
    func_remove_user() {
      this.$iosConfirm("Удалить пользователя?").then(() => {
        let url = "/remove_user";
        window.sendRequest(url, { id: this.id }).then(() => {
          this.$emit("remove_user");
        });
      });
    },

    func_save_info(balance) {
      let url = "/save_user_info";
      if (balance) {
        window
          .sendRequest(url, {
            user_info_id: this.id,
            login: this.user_info.login,
            phone: this.user_info.phone,
            email: this.user_info.email,
            chat: this.user_info.chat,
            balance: this.new_balance,
          })
          .then(() => {
            this.user_info.balance =
              Number(this.user_info.balance) + Number(this.new_balance);
            this.new_balance = 0;
            this.refresh_finance = true;
            setTimeout(() => {
              this.refresh_finance = false;
              this.$emit("update_balance", this.user_info);
            }, 1000);
          });
      } else {
        window
          .sendRequest(url, {
            user_info_id: this.id,
            login: this.user_info.login,
            phone: this.user_info.phone,
            chat: this.user_info.chat,
            email: this.user_info.email,
          })
          .then();
      }
    },
  },
  mounted() {
    this.func_get_data();
  },
};
</script>

<style></style>
