<template>
  <div class="finance__list__wrapper">
    <table v-if="finance_list.length > 0">
      <thead>
        <tr>
          <th>Дата</th>
          <th></th>
          <th>Сумма</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in finance_list" :key="item.finance_id">
          <td>
            {{
              new Date(item.create_date).toLocaleDateString() +
              " " +
              new Date(item.create_date).toLocaleTimeString()
            }}
          </td>
          <td :style="{ color: func_get_color(item) }">{{ item.type }}</td>
          <td :style="{ color: func_get_color(item) }">{{ item.summ }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    user_id: {
      type: Number,
      required: false,
    },
    refresh_finance: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      finance_list: [],
    };
  },
  methods: {
    func_get_finance_list() {
      let url = "/get_finance_list";
      window
        .sendRequest(url, { user_id: this.user_id ? this.user_id : 0 })
        .then((result) => {
          if (!result) return;
          this.finance_list = result;
          this.$emit("load_end");
        });
    },
    func_get_color(item) {
      let balance = Number(item.summ);
      let color = "";
      if (balance <= 0) {
        color = "#c94e4e";
      } else if (balance > 0 && balance < 50) {
        color = "#9f6427";
      } else {
        color = "#217e2d";
      }

      if (item.type === "Администратор") {
        color = "#9f6427";
      }

      return color;
    },
  },
  watch: {
    refresh_finance: function (val) {
      if (val) {
        this.func_get_finance_list();
      }
    },
  },
  mounted() {
    this.func_get_finance_list();
  },
};
</script>

<style></style>
