<template>
  <div class="promo__wrapper">
    <div class="promo__header">
      <h4>Промо</h4>
    </div>
    <div class="promo__body">
      <div class="promo__items">
        <div class="promo__item header">
          <div class="col">Промокод</div>
          <div class="col">Пароль</div>
          <div class="col">Текст</div>
          <div class="col">Сумма</div>
        </div>
        <div class="promo__item" v-for="item in promo_list" :key="item.id">
          <div class="col">
            <input
              type="text"
              v-model="item.promo_code"
              @change="func_update_item(item)"
            />
          </div>
          <div class="col">
            <input
              type="text"
              v-model="item.promo_password"
              @change="func_update_item(item)"
            />
          </div>
          <div class="col">
            <input
              type="text"
              v-model="item.title"
              @change="func_update_item(item)"
            />
          </div>
          <div class="col">
            <input
              type="number"
              v-model="item.summ"
              @change="func_update_item(item)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      promo_list: [],
    };
  },
  methods: {
    func_get_promo() {
      window.sendRequest("/get_admin_promo", {}).then((result) => {
        if (!result) return;
        this.promo_list = result;
      });
    },
    func_update_item(obj) {
      if (!obj) return;
      if (obj.summ.toString().length === 0) obj.summ = Number(0).toFixed(2);
      window.sendRequest("/save_admin_promo", obj);
    },
  },
  mounted() {
    this.func_get_promo();
  },
};
</script>

<style>
.promo__wrapper {
}
.promo__wrapper .promo__header {
  text-align: center;
}
.promo__wrapper .promo__header h4 {
  text-align: center;
  font-size: 25px;
  color: #525252;
  font-weight: normal;
  margin-bottom: 0em;
}
.promo__wrapper .promo__body {
  margin-top: 30px;
}
.promo__wrapper .promo__body .promo__items .promo__item {
  display: flex;
}
.promo__wrapper .promo__body .promo__items .promo__item.header {
  text-align: center;
}
.promo__wrapper .promo__body .promo__items .promo__item .col {
  padding: 5px;
}
.promo__wrapper .promo__body .promo__items .promo__item .col:nth-child(1) {
  flex-basis: 200px;
}
.promo__wrapper .promo__body .promo__items .promo__item .col:nth-child(3) {
  flex-basis: 350px;
}
.promo__wrapper .promo__body .promo__items .promo__item .col:nth-child(2) {
  flex-basis: 200px;
}
.promo__wrapper .promo__body .promo__items .promo__item .col input {
  width: 100%;
  background: #333;
  border-radius: 5px;
  outline: none;
  color: #fff;
  border: none;
  padding: 5px;
  padding-left: 10px;
}
</style>
